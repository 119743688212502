import { VStack } from '@chakra-ui/react';
import { UserFeedCard } from 'components';
import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useCustomerInfo, useModalActions, useUpdateGalleryPhoto } from 'hooks';
import mime from 'mime';
import { useState } from 'react';
import { Cropper, CropperRef } from 'react-advanced-cropper';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ParamType } from 'types';
import 'react-advanced-cropper/dist/style.css';

export const EditGalleryPhoto = (): JSX.Element => {
  const [t] = useTranslation();
  const [blob, setBlob] = useState<Blob | null>(null);
  const [feedImageUrl, setFeedImageUrl] = useState<string | null>(null);

  const { customerId } = useParams();
  const { isOpen, onClose, meta } = useModalActions(ModalType.EditGalleryPhoto);
  const { data: user } = useCustomerInfo(customerId || '');
  const { mutate: updateGalleryPhoto } = useUpdateGalleryPhoto();

  const onSave = async () => {
    if (!blob || !meta?.photo) return;

    const buffer = await blob.arrayBuffer();
    const croppedImageFile = new File(
      [buffer],
      `cropped-image.${mime.getExtension(blob.type)}`,
      { type: blob.type }
    );

    updateGalleryPhoto(
      {
        photoId: meta.photo.id,
        croppedImage: croppedImageFile
      },
      {
        onSettled: () => {
          setBlob(null);
          setFeedImageUrl(null);
        }
      }
    );
  };

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.save'),
      colorScheme: 'primary',
      onClick: onSave
    }
  ];

  const onChange = (cropper: CropperRef) => {
    setFeedImageUrl(cropper.getCanvas()?.toDataURL() || null);
    cropper.getCanvas()?.toBlob((blob) => {
      setBlob(blob);
    });
  };

  return (
    <ModalContainer
      header={t('attribute.title.edit_photo')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset',
        paddingY: 0,
        marginY: '-20px'
      }}
      size="5xl"
    >
      <VStack spacing={2}>
        <Cropper
          src={meta?.photo.imageUrl}
          onChange={onChange}
          style={{ maxHeight: '600px', width: '100%' }}
          className="cropper"
        />
        {user && (
          <UserFeedCard
            user={{
              displayedNickname: user.displayedNickname,
              country: user.bio.country,
              city: user.bio.city,
              state: user.bio.state,
              birthDate: user.bio.birthDate,
              online: user.system.online,
              lastOnlineAt: user.system.lastAccessAt,
              motto: user.bio.motto
            }}
            imageUrl={feedImageUrl || meta?.photo.imageUrl || ''}
          />
        )}
      </VStack>
    </ModalContainer>
  );
};
