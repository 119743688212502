import { FormField, InputElement } from 'enum';
import { ParameterType } from 'enum/api';
import { DefaultValues } from 'react-hook-form';
import { ICustomer } from 'types/customer';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { AccountSettingsFormData } from './AccountSettings.types';

export const customerAccountSettingsFormSchema = {
  fields: {
    [FormField.RelationshipSettings]: {
      type: InputElement.Select,
      translationKey: 'relationship_settings',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(
          ParameterType.RelationshipSetting,
          t,
          settings
        )
    },
    [FormField.AvailabilityType]: {
      type: InputElement.Select,
      translationKey: 'availability_type',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.AvailabilityType, t, settings)
    },
    [FormField.BusinessType]: {
      type: InputElement.Select,
      translationKey: 'business_type',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.BusinessType, t, settings)
    },
    [FormField.RelationshipTarget]: {
      type: InputElement.Select,
      translationKey: 'relationship_target',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(
          ParameterType.RelationshipTarget,
          t,
          settings
        )
    },
    [FormField.IncomeType]: {
      type: InputElement.Select,
      translationKey: 'income_type',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.IncomeType, t, settings)
    },
    [FormField.ProgramType]: {
      type: InputElement.Select,
      translationKey: 'program_type',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.ProgramType, t, settings),
      getIsDisabled: () => true
    },
    [FormField.Suspicious]: {
      type: InputElement.Toggle,
      translationKey: 'suspicious'
    },
    [FormField.ForceOnline]: {
      type: InputElement.Toggle,
      translationKey: 'force_online'
    },
    [FormField.Language]: {
      type: InputElement.MultiSelect,
      translationKey: 'language_other',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.Language, t, settings)
    },
    [FormField.Interest]: {
      type: InputElement.MultiSelect,
      translationKey: 'interest_other',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.Interest, t, settings)
    }
  },
  gridLayout: {
    templateAreas: `
    "${FormField.RelationshipSettings} ${FormField.AvailabilityType} ${FormField.Suspicious}"
    "${FormField.BusinessType} ${FormField.RelationshipTarget} ${FormField.ForceOnline}"
    "${FormField.IncomeType} ${FormField.ProgramType} ."
    "${FormField.Language} ${FormField.Language} ${FormField.Language}"
    "${FormField.Interest} ${FormField.Interest} ${FormField.Interest}"
  `,
    gridTemplateColumns: '1fr 1fr 1fr',
    rowGap: 6,
    columnGap: 10,
    alignItems: 'center'
  }
} as const;

export const accountSettingsValidationSchema: yup.SchemaOf<AccountSettingsFormData> =
  yup.object().shape({
    [FormField.RelationshipSettings]: yup.string().optional().nullable(),
    [FormField.AvailabilityType]: yup.string().optional().nullable(),
    [FormField.BusinessType]: yup.string().optional().nullable(),
    [FormField.RelationshipTarget]: yup.string().optional().nullable(),
    [FormField.IncomeType]: yup.string().optional().nullable(),
    [FormField.Motto]: yup.string().ensure(),
    [FormField.Language]: yup.array(yup.string().required()).ensure(),
    [FormField.Interest]: yup.array(yup.string().required()).ensure(),
    [FormField.AboutMe]: yup.string().ensure(),
    [FormField.ProgramType]: yup.string().optional().nullable(),
    [FormField.Suspicious]: yup.boolean().defined(),
    [FormField.ForceOnline]: yup.boolean().defined()
  });

export const getDefaultFormValues = (
  customer: ICustomer
): DefaultValues<AccountSettingsFormData> => ({
  [FormField.RelationshipSettings]: customer.config.relationshipSettings,
  [FormField.AvailabilityType]:
    customer.bio?.[ParameterType.AvailabilityType] || null,
  [FormField.BusinessType]: customer.bio?.[ParameterType.BusinessType] || null,
  [FormField.RelationshipTarget]: customer.config.relationshipTarget,
  [FormField.IncomeType]: customer.bio?.[ParameterType.IncomeType] || null,
  [FormField.Language]: customer.bio[ParameterType.Language],
  [FormField.Interest]: customer.bio[ParameterType.Interest],
  [FormField.ProgramType]: customer.programType || null,
  [FormField.Suspicious]: customer.system?.suspicious || false,
  [FormField.ForceOnline]: customer.system?.enabledForceOnline || false
});
